export const rayActionTypes = {
  SET_SHOW_RAY_CHAT: 'SET_SHOW_RAY_CHAT',
  SET_CHAT_ID: 'SET_CHAT_ID',
  SET_FEEDBACKS: 'SET_FEEDBACKS',
  SET_SHOW_FEEDBACK_FORM: 'SET_SHOW_FEEDBACK_FORM',
} as const;

export const initialValue = {
  showRayChat: false,
  chatId: '',
  feedbacks: {},
  showFeedbackForm: false,
};
