import {
  ActionList,
  ActionListItem,
  Box,
  Dropdown,
  DropdownOverlay,
  PlusIcon,
  SelectInput,
} from '@razorpay/blade/components';
import useSalaryComponentsList from 'components/RunPayroll/Popups/EditSalary/hooks/useSalaryComponentsList';
import AutoCompleteV2 from 'components/ui/AutoCompleteV2/AutoCompleteV2';
import { StandardSecondaryButton } from 'components/ui/Button';
import {
  ReviewSection,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
} from 'components/WizardViews/components/review';
import { earningsExemptionDisplayText } from 'components/WizardViews/SalaryComponents/utils';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { dateFormats, getRunPayrollMonthList } from 'utils/Dates';
import { useSalaryComponentHelpers } from '../hooks';
import { EarningsComponentType } from '../types';
import { capitalizeAllWordsInString } from 'utils/Strings';

type ComponentWidgetV1Props = {
  name?: string;
};

const tdsDeductionTypes = {
  IMMEDIATE: { description: 'Deduct TDS from this payment itself', title: 'From this payment' },
  PRORATE: {
    description: 'Credit the entire amount, and deduct TDS from future payroll',
    title: 'From future payroll',
  },
};

const ComponentWidgetV1 = ({ name }: ComponentWidgetV1Props) => {
  const { openEarningsCreation } = useSalaryComponentHelpers();
  const [selectedComponent, setSelectedComponent] = useState<EarningsComponentType | null>(null);
  const [payrollMonth, setPayrollMonth] = useState(format(new Date(), dateFormats.yearMonthDate));
  const [tdsDeductionType, setTdsDeductionType] = useState<string>();
  const taxBehaviour = selectedComponent?.settings.behaviour.taxBehaviour;
  const { searchSalaryComponents, isFetchingSalaryComponents } =
    useSalaryComponentsList<EarningsComponentType>({
      requestParams: {
        category: 'EARNINGS_CATEGORY',
        payType: 'LIST_ADHOC_TYPE',
        status: 'COMPONENT_STATUS_ACTIVE',
      },
    });

  const isTaxDeductionBehaviourInstant = selectedComponent
    ? selectedComponent.settings.behaviour.taxBehaviour?.taxDeductionBehaviour === 'instant'
    : false;

  const monthList = getRunPayrollMonthList(payrollMonth);

  return (
    <Box display="flex" flexDirection="column" gap="spacing.6" paddingBottom="spacing.10">
      <Dropdown _width="100%">
        <SelectInput
          isRequired
          label="Select Payroll Month"
          name="pm"
          value={payrollMonth}
          onChange={({ name, values }) => {
            setPayrollMonth(values[0]);
          }}
        />
        <DropdownOverlay>
          <ActionList>
            {monthList.map((month) => (
              <ActionListItem
                key={month.toDateString()}
                value={format(month, 'yyyy-MM-dd')}
                title={format(month, 'MMMM yyyy')}
              />
            ))}
          </ActionList>
        </DropdownOverlay>
      </Dropdown>
      <Box>
        <AutoCompleteV2
          name={name}
          label="Select component"
          placeholder="Select component"
          options={searchSalaryComponents()}
          isDisabled={isFetchingSalaryComponents}
          itemToKey={(item) => item.id}
          itemToLabel={(item) => capitalizeAllWordsInString(item.name)}
          itemToDescription={(item) => item.description || ''}
          value={selectedComponent ? selectedComponent.id : undefined}
          onChange={(item) => setSelectedComponent(item)}
          onClearSelection={() => setSelectedComponent(null)}
          footer={
            <StandardSecondaryButton
              className="w-full h-14 flex items-center gap-3 justify-center"
              size="sm"
              onClick={openEarningsCreation}>
              <PlusIcon size="small" />
              Create addition
            </StandardSecondaryButton>
          }
        />

        {selectedComponent ? (
          <Box paddingTop="spacing.4">
            <ReviewSection>
              <ReviewSectionHeader title="Component" subtitle="details" />
              <ReviewSectionItemContainer>
                {taxBehaviour?.taxExemptInOldRegime && (
                  <ReviewSectionItem
                    label="Tax exempted in Old Regime"
                    value={taxBehaviour?.taxExemptInOldRegime ? 'Yes' : 'No'}
                  />
                )}
                {taxBehaviour?.taxExemptInNewRegime && (
                  <ReviewSectionItem
                    label="Tax exempted in New Regime"
                    value={taxBehaviour?.taxExemptInNewRegime ? 'Yes' : 'No'}
                  />
                )}
                <ReviewSectionItem
                  label="Exemption under section"
                  value={earningsExemptionDisplayText(taxBehaviour?.taxExemptionSection)}
                />
              </ReviewSectionItemContainer>
            </ReviewSection>
          </Box>
        ) : null}
      </Box>

      {isTaxDeductionBehaviourInstant ? (
        <Dropdown>
          <SelectInput
            isRequired
            value={tdsDeductionType}
            label="Please choose how to recover TDS for this payment"
            placeholder="Select Option"
            name="tds-recovery-option"
            onChange={({ name, values }) => setTdsDeductionType(values[0])}
          />
          <DropdownOverlay>
            <ActionList>
              {Object.entries(tdsDeductionTypes).map(([key, item]) => (
                <ActionListItem
                  key={key}
                  title={item.title}
                  value={key}
                  description={item.description}
                />
              ))}
            </ActionList>
          </DropdownOverlay>
        </Dropdown>
      ) : null}
    </Box>
  );
};

export default ComponentWidgetV1;
