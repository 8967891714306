import { BladeProvider } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import React, { useEffect } from 'react';
import { SideBarV2 } from './SideBarV2';
import { SidebarApiSchemaContract } from 'schemas/SidebarApiSchema';

const SideBarV2Widget = ({ sidebar }: { sidebar: SidebarApiSchemaContract }) => {
  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = React.useState(false);

  const toggleMobileSideBar = () => {
    setIsMobileSideBarOpen((isOpen) => !isOpen);
  };

  const closeSidebar = () => {
    setIsMobileSideBarOpen(false);
  };

  useEffect(() => {
    window.payroll.toggleLeftSidebar = toggleMobileSideBar;
  }, []);

  return (
    <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
      <SideBarV2
        sidebar={sidebar}
        isMobileSideBarOpen={isMobileSideBarOpen}
        closeSidebar={closeSidebar}
      />
    </BladeProvider>
  );
};

export default SideBarV2Widget;
