import styled from 'styled-components';

export const EntryPointWrapper = styled.div(
  ({ theme }) => `
        display: flex;
        background-color: ${theme.colors.surface.background.gray.subtle};
        flex-wrap: wrap;
        align-items: center;
        border-radius: ${theme.border.radius.max}px;
      `,
);

export const HighlightWrapper = styled.div(
  ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: ${theme.spacing[2]}px;
        padding: ${theme.spacing[4]}px ${theme.spacing[6]}px;
        background: linear-gradient(91deg, ${theme.colors.surface.background.gray.subtle} 0%, ${theme.colors.surface.background.gray.intense} 104.41%);
        border-top-left-radius: ${theme.border.radius.max}px;
        border-bottom-left-radius: ${theme.border.radius.max}px;
      `,
);

export const CTAWrapper = styled.button(
  ({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: ${theme.spacing[2]}px;
        padding: ${theme.spacing[4]}px ${theme.spacing[6]}px;
        border-top-right-radius: ${theme.border.radius.max}px;
        border-bottom-right-radius: ${theme.border.radius.max}px;
        background-color: ${theme.colors.surface.background.gray.subtle};
        cursor: pointer;
      `,
);
