import { BladeProvider } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import React from 'react';
import HeaderV2 from './HeaderV2';
import { GlobalSearchProps } from 'components/GlobalSearch/types';

const HeaderV2Widget = ({
  doesPageRequireLogin,
  name,
  companyBrandName,
  companyLogo,
  specialPlatformPermissions = [],
  permissions = [],
  peopleId,
  orgId,
  userPhoto,
  userType,
  features = {},
  roleName = null,
  hasMultipleOrgs = false,
}: {
  doesPageRequireLogin: boolean;
  name?: string;
  companyBrandName?: string;
  orgId?: number;
  userPhoto?: string | null;
  companyLogo?: string;
  hasMultipleOrgs: boolean;
  roleName?: string | null;
} & GlobalSearchProps) => {
  const toggleMobileSideBar = () => {
    if (window.payroll && window.payroll.toggleLeftSidebar) {
      window.payroll.toggleLeftSidebar();
    }
  };

  return (
    <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
      <HeaderV2
        doesPageRequireLogin={doesPageRequireLogin}
        name={name}
        companyBrandName={companyBrandName}
        companyLogo={companyLogo}
        userType={userType}
        permissions={permissions}
        specialPlatformPermissions={specialPlatformPermissions}
        peopleId={peopleId}
        orgId={orgId}
        userPhoto={userPhoto}
        features={features}
        toggleMobileSideBar={toggleMobileSideBar}
        hasMultipleOrgs={hasMultipleOrgs}
        roleName={roleName}
      />
    </BladeProvider>
  );
};

export default HeaderV2Widget;
