import React from 'react';

import { WidgetTypes } from './widgetTypes';

import ReimbursementProof from '../BulkExport/ReimbursementProof';
import EmployeeDocuments from 'components/BulkExport/EmployeeDocuments';
import EmployeePayslips from 'components/BulkExport/EmployeePayslips';
import TaxDeductionReports from 'components/BulkExport/TaxDeductionReports';
import BulkDownloadPolling from 'components/BulkDownloadPolling';
import ToastContainer from 'components/ui/Toast/ToastContainer';
import EmployeePayslipsAdmin from 'components/BulkExport/EmployeePayslipsAdmin';
import OtpComponent from 'components/Otp/OtpComponent';
import SalaryRegisterFilters from 'components/SalaryRegister/Filters';
import TaxOptimizerEntryPoint from 'components/PayrollAIAssistant/EntryPoint';
import OtpModalWidget from 'components/Otp/OtpModalWidget';
import SideBarV2Widget from 'components/BaseShell/SideBar/SideBarV2Widget';
import SalaryExplainerEntryPoint from 'components/AiSalaryExplainer/EntryPoint';
import ComponentWidgetV1 from 'components/SalaryComponents/components/ComponentWidgetV1';
import HeaderV2Widget from 'components/BaseShell/Header/HeaderV2Widget';

const GlobalSearch = React.lazy(() => import('components/GlobalSearch/GlobalSearch'));

const WidgetMap = {
  [WidgetTypes.OTP_COMPONENT]: OtpComponent,
  [WidgetTypes.REIMBURSEMENT_BULK_EXPORT]: ReimbursementProof,
  [WidgetTypes.EMPLOYEE_PAYSLIPS_EXPORT]: EmployeePayslips,
  [WidgetTypes.EMPLOYEE_DOCUMENTS_EXPORT]: EmployeeDocuments,
  [WidgetTypes.TAX_DEDUCTION_EXPORT]: TaxDeductionReports,
  [WidgetTypes.BULK_DOWLOAD_POLL]: BulkDownloadPolling,
  [WidgetTypes.TOAST_SYSTEM]: ToastContainer,
  [WidgetTypes.EMPLOYEE_PAYSLIPS_ADMIN_EXPORT]: EmployeePayslipsAdmin,
  [WidgetTypes.SALARY_REGISTER_FILTERS]: SalaryRegisterFilters,
  [WidgetTypes.GLOBAL_SEARCH]: GlobalSearch,
  [WidgetTypes.AI_TAX_OPTIMIZER]: TaxOptimizerEntryPoint,
  [WidgetTypes.TWO_FACTOR_AUTH_MODAL]: OtpModalWidget,
  [WidgetTypes.SIDEBAR_NAV]: SideBarV2Widget,
  [WidgetTypes.AI_SALARY_EXPLAINER]: SalaryExplainerEntryPoint,
  [WidgetTypes.COMPONENT_AUTOCOMPLETE_WIDGET]: ComponentWidgetV1,
  [WidgetTypes.TOP_NAV]: HeaderV2Widget,
};

const V1Widgets = ({ elementType, props }: { elementType: string; props: any }) => {
  const Component = WidgetMap[elementType] as React.ElementType;

  return (
    <>
      {/** TODO: remove this fallback and define custom loaders for components that are lazily loaded*/}
      <React.Suspense fallback={<></>}>
        <Component {...props} />
      </React.Suspense>
    </>
  );
};

export default V1Widgets;
