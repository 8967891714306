import React, { useReducer } from 'react';
import { createContext, useContext } from 'react';
import { RayContextValue } from './types';
import { initialValue } from './data';
import { reducer } from './reducer';

const RayContext = createContext<RayContextValue>({ state: initialValue, dispatch: () => {} });

export const RayContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <RayContext.Provider
      value={{
        state,
        dispatch,
      }}>
      {children}
    </RayContext.Provider>
  );
};

export const useRayContext = () => {
  return useContext(RayContext);
};
