import { BladeProvider, Box, ArrowRightIcon, Heading, Link } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import React from 'react';
import sparkle from '../../../assets/sparkle.svg';
import { CTAWrapper, EntryPointWrapper, HighlightWrapper } from './styles';
import { useRayContext } from 'components/RayAiBot/context/RayContext';
import { rayActionTypes } from 'components/RayAiBot/context/data';

export const SalaryExplainerEntryPoint = () => {
  const { dispatch, state } = useRayContext();

  return (
    <EntryPointWrapper>
      <HighlightWrapper>
        <Box display="flex" gap="spacing.3" alignItems="center">
          <img src={sparkle} width="20px" height="20px" />
          <Heading color="surface.text.gray.subtle" size="small" weight="regular">
            Questions about this Payslip?
          </Heading>
        </Box>
      </HighlightWrapper>
      <CTAWrapper
        onClick={() => {
          dispatch({
            type: rayActionTypes.SET_SHOW_RAY_CHAT,
            payload: true,
          });
        }}>
        <Link variant="button" iconPosition="right" icon={ArrowRightIcon}>
          {state.chatId ? 'Continue' : 'Get Started'}
        </Link>
      </CTAWrapper>
    </EntryPointWrapper>
  );
};

const EntryPointWithProvider = () => {
  return (
    <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
      <SalaryExplainerEntryPoint />
    </BladeProvider>
  );
};

export default EntryPointWithProvider;
